
.layout {
  font-size: 25px;
}

.layout__container {
  @include make-container();
  max-width: 1811px;
}

.layout__header {
  background-color: var(--layout__header---bg);
  color: var(--layout__header---color);
  padding: 25px 0;
}

.layout__header-inner {
  //@extend %space-between-centered;
  //flex-wrap: wrap;
}

.layout__logo {
}

.layout__headline {
  text-transform: uppercase;
  font-weight: bold;
  font-size: $font-size--xl !important;

  a {
    color: var(--color--primary);
    text-decoration: none;

    &:hover, &:focus {
      color: var(--color--primary);
    }
  }
}

.layout__nav {
  display: flex;
  align-items: center;

  span {
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }

  img {
    margin-left: 10px;
    width: 25px;
  }
}

.layout__main {
  margin: 30px 0;
}

.layout__footer {
}
