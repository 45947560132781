@import "global";

#myopus-eu {
  font-size: 25px;

  * {
    font-family: $font-family--default !important;
  }

  // Layout
  @import "layout/_index";

  // atoms
  @import "atoms/_index";

  // molecules
  @import "molecules/_index";

  // organisms
  @import "organisms/_index";
}

// vendor
@import "vendor/_index";
