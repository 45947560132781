.o-cards {
  .m-card {
  }
}

.o-cards--fill {

  .m-card {
    .m-card__title {
      font-size: $font-size--s !important;
    }
  }
}
