.m-off-canvas {
}

.m-off-canvas__nav {
  padding: 0;
  margin: 0;
}
.m-off-canvas__nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.m-off-canvas__nav-item {
  a {
    display: block;
    color: $color--secondary;
    text-decoration: none;
    padding: 10px 15px;

    &:hover, &:focus {
      background-color: $color--secondary;
      color: $color--primary;
    }
  }
}

