// BEACHTEN: Datei wird durch das "icon-font-generator"-Package generiert - keine manuellen Anpassungen vornehmen!
// @see package.json → scripts
// Schriftennamen dürfen nicht länger als 27 Zeichen sein (für IE...)

$icon-font-name: "opus-20210409134348";

@font-face {
    font-family: $icon-font-name;
    src: url("../../../fonts/icons/opus-20210409134348.eot?d2133019f82efa2891369c482c69228a?#iefix") format("embedded-opentype"),
url("../../../fonts/icons/opus-20210409134348.woff2?d2133019f82efa2891369c482c69228a") format("woff2"),
url("../../../fonts/icons/opus-20210409134348.woff?d2133019f82efa2891369c482c69228a") format("woff"),
url("../../../fonts/icons/opus-20210409134348.ttf?d2133019f82efa2891369c482c69228a") format("truetype"),
url("../../../fonts/icons/opus-20210409134348.svg?d2133019f82efa2891369c482c69228a#opus-20210409134348") format("svg");
}

//i {
//    line-height: 1;
//}

%icon-font-defaults {
    font-family: $icon-font-name !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons: (
    'arrow-right': "\f101",
    'globe': "\f102",
    'mail': "\f103",
    'phone': "\f104",
    'pin': "\f105",
    'reset': "\f106",
    'social-facebook': "\f107",
    'social-instagram': "\f108",
    'social-linkedin': "\f109",
    'social-pinterest': "\f10a",
    'social-twitter': "\f10b",
    'social-youtube': "\f10c",
    'trash': "\f10d",
    'upload': "\f10e",
);
