
:root {
  --color--primary: #{$color--primary};
  --color--primary--light: #{$color--primary--light};
  --color--secondary: #{$color--secondary};
  --color--tertiary: #{$color--tertiary};
  --color--quaternary: #{$color--quaternary};
  --color--1: #{$color--1};
  --color--2: #{$color--2};
  --color--3: #{$color--3};
  --color--4: #4CAF50;
  --color--error: red;
  --color--white: #{$color--white};
  --color--dark: #{$color--dark};
  --color--default: var(--color--primary);
  --color--hr: lighten($color--primary, 50%);


  --hamburger---color: var(--color--primary);
  --layout__header---bg: var(--color--secondary);
  --layout__header---color: var(--color--primary);

  --o-footer__meta---bg: var(--color--secondary);
  --o-footer__meta---color: var(--color--primary);
  --o-footer__legal---bg: var(--color--primary);
  --o-footer__legal---color: var(--color--white);

  --o-overview__headline---color: var(--color--primary);

  --tooltip---bg: var(--color--primary);
  --tooltip---color: var(--color--white);
  --tooltip---border: var(--color--primary);
  --tooltip__popover---bg: #f9f9f9;
  --tooltip__popover---color: var(--color--primary);
  --tooltip__popover---border: #f9f9f9;
  --tooltip__popover---shadow: rgba(0, 0, 0, .1);

  --a-btn--primary---color: var(--color--white);
  --a-btn--primary---bg: var(--color--primary);

  --a-btn--secondary---color: var(--color--primary);
  --a-btn--secondary---bg: transparent;
  --a-btn--secondary--hover---bg: #d2dff6;

  --a-btn--link---color: var(--color--primary);

  --m-card__trigger---color: var(--color--primary);
  --m-card__title---color: var(--color--primary);
  --m-card__title---bg: rgba(255, 255, 255, .8);
  --m-cart__actions---color: var(--color--primary);

  --a-headline---color: var(--color--primary);
  --a-headline--completed---color: var(--color--4);


  --m-form__placeholder---color: var(--color--3);
  --m-form__a---color: var(--color--primary);
  --m-form__control---color: var(--color--3);
  --m-form__control---bg: var(--color--white);
  --m-form__control---border: var(--color--3);
  --m-form__checkbox---border: var(--color--primary--light);
  --m-form__checkbox---bg: var(--color--secondary);
  --m-form__select---border: var(--color--3);
  --m-form__select---bg: var(--color--white);
  --m-form__select---color: var(--color--3);
  --m-form__message--error---color: var(--color--error);

  --m-select---bg:var(--color--white);
  --m-select---shadow:#89A5CA;
  --m-select__item--even---bg:#6B7EAC0D;
  --m-select__link---color:#686868;
  --m-select__item--selected---color:var(--color--primary);

  --m-product-card_inner---bg: var(--color--white);
  --m-product-card_inner---shadow: #F4F4F4;
  --m-product-card__headline---color: var(--color--primary);

  --m-progress__status---bg: var(--color--tertiary);

}
