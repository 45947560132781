.a-btn {
  background-color: transparent;
  border-radius: 16px;
  padding: 20px 18px;
  line-height: 1;
  outline: none;
  border: 0 none;
  appearance: none;
  cursor: pointer;
}

.a-btn--primary {
  background-color: var(--a-btn--primary---bg);
  color: var(--a-btn--primary---color);
  text-decoration: none;
  text-transform: uppercase;

  &:hover, &:focus {
    filter: brightness(85%);
  }

  &[disabled] {
    filter: opacity(70%);
    cursor: not-allowed;
  }
}

.a-btn--secondary {
  background-color: var(--a-btn--secondary---bg);
  color: var(--a-btn--secondary---color);
  text-decoration: none;

  &:hover, &:focus {
    background-color: var(--a-btn--secondary--hover---bg);
  }
}

.a-btn--link {
  display: flex;
  justify-content: center;
  align-content: space-between;
  background-color: transparent;
  color: var(--a-btn--link---color);
  text-decoration: none;

  img {
    //margin-left: 23px;
  }

  //&:hover, &:focus {
  //  color: lighten($color--primary, 30%);
  //}
}

.a-btn--upload {
  @extend .a-btn--primary;
  padding: 10px 18px;
  span {
    //font-size: 1.8em;
    //margin-left: 10px;
  }
}

.a-btn--arrow {
  @extend .a-btn--primary;
  span {
    //font-size: .9em;
    //margin-left: 10px;
  }
}

.a-btn--big {
  line-height: 1;
  padding: $spacing--default 18px;
}

.a-btn--wider {
  padding: 20px 55px;
}

.a-btn--block {
  display: block;
  text-align: center;
}
