.o-footer {
}


.o-footer__meta {
  background-color: var(--o-footer__meta---bg);

}
.o-footer__meta-inner {
  @extend %space-between-centered;
  flex-wrap: wrap;
}

.o-footer__contact {
  font-size: $font-size--s !important;
  font-weight: 600;
  line-height: 1;

  a {
    color: var(--o-footer__meta---color);
    text-decoration: none;
  }
}

.o-footer__footnotes {
}

.o-footer__contact-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-footer__contact-list-item {

  &:not(:last-child) {
  }
}

.o-footer__social {
}

.o-footer__social-list {
}

.o-footer__social-list-item {
  &:not(:last-child) {
  }

  a {
    color: var(--o-footer__meta---color);
  }
}

.o-footer__legal {
  background-color: var(--o-footer__legal---bg);
  color: var(--o-footer__legal---color);
  font-size: $font-size--xs !important;

   a {
     color: var(--o-footer__legal---color);
   }
}
.o-footer__legal-inner {
}

.o-footer__legal-nav {
}

.o-footer__legal-nav-list {
}

.o-footer__legal-nav-list-item {
  &:not(:last-child)::after {
  }
  a {
    text-decoration: none;
  }
}
