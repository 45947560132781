.m-card {
  //position: relative;
}

.m-card__reset {
  //position: absolute;
  //top: 0;
  //left: 20px;
  //z-index: 5;
  //display: inline-block;
  //cursor: pointer;

  img {
    //width: 50px;
    //height: 50px;
  }
}

.m-card--checked {
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    top: -25px;
    right: -10px;
    z-index: 2;
    content: '';
    background-image: url('../../../../images/icons/checked.svg');
    width: 50px;
    height: 50px;
    display: block;
  }
}

.m-card__trigger {
  //overflow: hidden;
  border: 1px solid var(--m-card__trigger---color);
  border-radius: 2px;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //text-align: center;
  //width: 100%;
  //text-decoration: none;
  //padding: 20px 30px;
  //min-height: 320px;
  //position: relative;

  @include media-breakpoint-up(md) {
    //padding: 50px 60px;
  }

  &:hover {
    //background-color: darken($color--secondary, 5%);
  }


  .m-card--checked & {
  }

}

.m-card__image {
  //margin-bottom: $spacing--default*2;
  //line-height: 1;

  img {
    //height: 80px;
  }
}

.m-card__title {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //padding: $spacing--default/2 0;
  color: var(--m-card__title---color);
  font-size: $font-size--m !important;
  font-weight: bold;
  line-height: 1;
  background-color: var(--m-card__title---bg);
  transition: .5s all;

  .m-card__trigger:hover & {
    //padding-bottom: $spacing--default;
  }
}


.m-card--light {
  .m-card__trigger {
    //background: url('../../../../images/pages/subprocess-light.jpg') no-repeat center center;
    //background-size: cover;
  }
}

.m-card--shading {
  .m-card__trigger {
    //background: url('../../../../images/pages/subprocess-shading.jpg') no-repeat center center;
    //background-size: cover;
  }
}

.m-card--surveillance {
  .m-card__trigger {
    //background: url('../../../../images/pages/subprocess-surveillance.jpeg') no-repeat center center;
    //background-size: cover;
  }
}
