@import "generated--variables-icons";

// FALLBACK-ICONS ------------------
// @see https://en.wikipedia.org/wiki/List_of_Unicode_characters bzw.
//      https://unicode.org/emoji/charts/full-emoji-list.html
$fallbackIcons: (
        'crosss': "+",
        'check': "",
        'download': "«",
        'upload': "«",
        'plus': "+",
        'minus': "+",
        'trash': "+",
        'search': "+",
        'caret': "+",
);
// ---------------------------------
$ready-class-iconfont: 'fonts-loaded--iconfont';


%iconfont-icon-default {
  display: inline-block;
  font-family: $icon-font-name;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;

  //@at-root .#{$ready-class-iconfont} & {
  //  font-family: $icon-font-name;
  //}
  @at-root & {
    font-family: $icon-font-name;
  }
}

@mixin iconfont-icon($iconName, $position: 'before', $withSetup: true) {
  $position: if($position == 'before', 'before', 'after');

  &:#{$position} {
    @if ($withSetup == true) {
      @extend %iconfont-icon-default;
    }
    content: map_get($fallbackIcons, $iconName);
  }

  //@at-root .#{$ready-class-iconfont} &:#{$position} {
  //  content: map_get($icons, $iconName);
  //}
  @at-root &:#{$position} {
    content: map_get($icons, $iconName);
  }
}

@mixin iconfont-icon-before($iconName, $withSetup: true) {
  @include iconfont-icon($iconName, 'before', $withSetup);
}

@mixin iconfont-icon-after($iconName, $withSetup: true) {
  @include iconfont-icon($iconName, 'after', $withSetup);
}

[class^="a-icon__"]:before,
[class^="a-icon__"][class$="--after"]:after {
  @extend %iconfont-icon-default;
}

// --------------
@each $key, $icon in $icons {
  %icon--#{$key} {
    @include iconfont-icon-before($key, $withSetup: true);
  }

  %after-icon--#{$key} {
    @include iconfont-icon-after($key, $withSetup: true);
  }

  .a-icon__#{$key} {
    @include iconfont-icon-before($key, $withSetup: false);

    &::before {
      margin-right: 5px;
    }
  }

  .a-icon__#{$key}--after {
    @include iconfont-icon-after($key, $withSetup: false);

    &::after {
      margin-left: 5px;
    }
  }
}
