.m-language-switcher {
  margin-left: 1em;
}

.m-language-switcher__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.m-language-switcher__item {
  cursor: pointer;
  text-decoration: none;
  color: $color--primary;
  display: flex;
  align-items: center;
  @include iconfont-icon('globe');
  &::before {
    margin-right: 5px;
  }
}

