@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

.h-completed {
  font-weight: bold;
  margin-bottom: $spacing--default ;

  .h-big {
    font-size: $font-size--xl !important;
    //margin-right: $spacing--default*2;
  }
  .h-code {
    //display: inline-block;
    color: $color--white;
    background-color: #4CAF50;
    padding: 3px 25px;
    border-radius: 16px;
  }
}
