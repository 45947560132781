.o-overview {}
.o-overview__headline {
  a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: $font-size--m !important;
    color: var(--o-overview__headline---color);
    font-weight: bold;
  }
}
.o-overview__items {
}
.o-overview__item {
}
.o-overview__ {}
