.o-main {
  background-color: var(--color--secondary);
  border: 2px solid var(--color--primary--light);
  border-radius: 9px;
}

.o-main__row {
  //@include make-row();
}

.o-main__column {
  //@include make-col-ready();
  //@include make-col(12);
    margin: $spacing--default*2 0;
    //padding: 0 $spacing--default;

  @include media-breakpoint-up(xl) {
    margin: $spacing--default*3 0;
    //padding: 0 $spacing--default*2;
  }
}

.o-main__column--left,
.o-main__column--right {

  @include media-breakpoint-up(lg) {
  }
}

.o-main__column--right {
  //text-align: center;

  @include media-breakpoint-up(lg) {
    //text-align: left;
    //align-self: center;
  }
}

.o-main__divider {
}
